.footer {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  vertical-align: bottom;
  width: 100%;
  font-family: Raleway;
}

.footer > h5 {
  font-weight: 100;
  font-size: medium;
  padding-left: 1%;
}
