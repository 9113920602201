.contact-container {
  background-image: url("/public/images/contact_bkgr.jpg");
  background-repeat: no-repeat;
  height: 1200px;
  width: auto;
  font-family: Raleway;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-container > h2 {
  font-weight: lighter;
  margin-top: auto;
}

.form-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.first-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.second-column {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
}

.text-input {
  height: 30px;
  width: 250px;
  margin-bottom: 2%;
}

.text-area-input {
  height: 250px;
  width: 300px;
}

.section-body {
  display: flex;
}

.form-button {
  display: flex;
  justify-content: center;
  margin-left: 3%;
  align-items: center;
  width: 100%;
  margin-top: 2%;
}

.button {
  width: 100%;
  align-self: auto;
  height: 30px;
  background-color: #607980;
  cursor: pointer;
  font-size: 15px;
  color: #fffdfa;
  border-radius: 10px;
  border-color: #607980;
}

@media screen and (max-width: 960px) {
  .form-body {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .title-container {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
  }

  .form-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .contact-container {
    background-image: url("/public/images/contact_bkgr.jpg");
    background-repeat: no-repeat;
    height: auto;
    width: 100%;
    z-index: -1;
    font-family: Raleway;
  }

  .section-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
