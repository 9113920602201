.welcome-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  width: 100%;
  margin-bottom: 4px;
}

.image-container > img {
  max-width: 100%;
  height: auto;
}

.title-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.east-bay {
  margin-top: -20px;
  text-align: center;
}

h1 {
  font-size: 50px;
  font-weight: 100;
  font-family: Raleway;
}

.title-container > h5 {
  font-weight: 500;
  margin-top: -10px;
  font-family: Raleway;
  margin-bottom: 30px;
}

@media screen and (max-width: 960px) {
  h1 {
    font-size: 30px;
    font-weight: 100;
    font-family: Raleway;
  }
}
