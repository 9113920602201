.gallery-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
  font-family: Raleway;
  background: #e8e6e6;
}

h4 {
  font-size: xx-large;
  font-weight: 100;
  text-align: center;
  margin: 0 auto;
}

@media screen and (max-width: 960px) {
  .gallery-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: auto;
    font-family: Raleway;
    background: #e8e6e6;
  }
}
