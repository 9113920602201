.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Raleway;
  margin-top: 0.5%;
  height: 100%;
}

.s-title {
  font-weight: lighter;
  margin-bottom: 0.2%;
}

.s-h2 {
  font-weight: 100;
}

.s-gallery-container {
  width: 100%;
  height: auto;
  margin-top: 1%;
}

.s-gallery-card {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Raleway;
  background: #e8e6e6;
  padding-bottom: 45px;
}

.s-gallery-card > p {
  font-size: 15px;
  width: 50%;
}

.s-gallery-image {
  width: 100px;
  height: 100px;
}

.s-gallery-card > h5 {
  font-size: 25px;
  width: 50%;
}

@media screen and (max-width: 960px) {
  .s-title {
    font-size: 25px;
    font-weight: lighter;
    margin-bottom: 0.2%;
  }

  .s-h2 {
    font-size: 20px;
    font-weight: 100;
    text-align: center;
  }
}
