.house-tour-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  width: 800px;
  height: 533px;
  object-fit: contain;
  border-radius: 10px;
  z-index: -1;
}

@media screen and (max-width: 850px) {
  .house-tour-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    width: 600px;
    height: 396px;
    border-radius: 10px;
    z-index: -1;
  }

  .swiper-slide > img {
    width: 600px;
    height: 396px;
  }
}

@media screen and (max-width: 400px) {
  .house-tour-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    width: 300px;
    height: 200px;
    border-radius: 10px;
    z-index: -1;
  }

  .swiper-slide > img {
    width: 300px;
    height: 200px;
  }
}
