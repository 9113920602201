.about-container {
  display: flex;
  font-family: Raleway;
}

.about-title {
  font-size: xx-large;
}

.about-text {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-right: 7%;
  margin-left: 7%;
  margin-top: 5%;
}

.kid-container {
  display: flex;
  justify-content: center;
  width: 1200px;
  margin-top: 0.5%;
  margin-right: 0.5%;
  border-radius: 10px;
}
.kid-container > img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}

@media screen and (max-width: 960px) {
  .about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Raleway;
  }

  .about-text {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 70%;
  }

  .kid-container {
    display: flex;
    justify-content: center;
    width: 300px;
    margin-top: 0.5%;
    margin-left: 6%;
    border-radius: 10px;
  }
}
