.navbar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Raleway;
}

.title-nav {
  font-size: 28px;
  font-family: Raleway;
  font-weight: 400;
  margin-top: 2%;
}

.navbar-selection-container {
  display: flex;
  align-items: horizontal;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin-top: 2%;
  background-color: #f1f1f1;
}

.nav-item {
  display: flex;
  height: 40px;
  padding-left: 2%;
  padding-right: 2%;
  align-items: center;
  color: black;
  text-decoration: none;
}

.nav-menu {
  display: flex;
  justify-content: center;
  align-self: center;
  list-style: none;
  width: 100%;
}

.menu-icon {
  display: none;
}

.nav-links-mobile {
  display: none;
}

@media screen and (max-width: 600px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    font-size: 1.2rem;
    padding-top: 20px;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    display: flex;
    justify-content: start;
    background: #3a3a3c;
    right: 0;
    left: 0;
    margin: 0 auto;
    height: 100vh;
    width: 100%;
    opacity: 0.98;
    transition: all 0.5s ease;
    z-index: 99;
  }

  .nav-links {
    display: flex;
    justify-content: center;
    width: 100%;
    display: table;
    color: #fffdfa;
    margin-left: -2%;
    padding-left: 2%;
  }

  .nav-item {
    padding-top: 20px;
    /* color: #fffdfa; */
  }

  .nav-links > div {
    color: #fffdfa;
  }

  .nav-links > div:hover {
    background-color: #fffdfa;
    color: #3a3a3c;
    border-radius: 10px;
    height: 30px;
    padding-top: 1%;
    width: 100%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    margin-bottom: -2%;
    transform: translate(-100%, 60%);
    font-size: 1.4rem;
    cursor: pointer;
    margin-top: -1%;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #3a3a3c;
    transition: 250ms;
  }

  .title-nav {
    font-size: 25px;
    font-family: Raleway;
    font-weight: 400;
    margin-top: 2%;
  }
}
.nav-links {
  padding-left: 2%;
  padding-right: 2%;
  align-self: center;
  color: black;
  text-decoration: none;
}
