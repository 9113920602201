.project-container {
  display: flex;
  font-family: Raleway;
  justify-content: center;
}

.overview-container {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-top: 5%;
  margin-right: 8%;
  position: sticky;
}

.project-title {
  font-size: xx-large;
}

.project-list-container {
  width: 40%;
}

.image-container > img {
  cursor: pointer;
  border-radius: 10px;
}

/* start of carousel functionality */
.return-button {
  align-self: center;
  font-family: Raleway;
  font-size: large;
  background-color: transparent;
  border-radius: 5px;
  padding: 10px;
  transition-duration: 0.4s;
}

.return-button:hover {
  background-color: black;
  color: #fffdfa;
  cursor: pointer;
}

.return-container {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

@media screen and (max-width: 960px) {
  .project-container {
    display: flex;
    font-family: Raleway;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .overview-container {
    width: 70%;
    margin-top: 5%;
  }

  .project-list-container {
    width: 70%;
  }
}
